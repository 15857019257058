<template>
  
  <div class="landing-container">

    <!-- Hero Section -->
    <div class="index-carousel">
      <div class="main masthead position-relative">
        <div class="masthead-gradient-overlay"></div>
        
        <div class="content-wrapper">
          <div class="container">
            <div class="row gx-5 align-items-center">
              <!-- Left Side Content -->
              <div class="col-lg-5 col-md-6">
                <div class="content-section">
                 
                  
                  <h1 class="headline">
                    🚀 Transform Your Product Photos into Realistic Lifestyle Images with AI
                  </h1>
                  <div class="briks-gradient-bar mb-4"></div>
                  <p class="description">
                    No more expensive photoshoots! Create professional lifestyle visuals in few clicks that boost engagement and sales
                  </p>

                  <div class="features-list">
                     <div class="feature-item">
                      <span>✅ Increase conversions with realistic, eye-catching lifestyle images</span>
                    </div> 
                    <div class="feature-item">
                      ✅ Save 90% of the time & cost compared to traditional photoshoots
                    </div>
                    <div class="feature-item">
                      ✅ Trusted by brands & influencers to scale online marketing and sales
                    </div> 
             
                  </div>
                  <b-button 
                    @click="navigateToCreateAgent"
                    class="start-button upload-btn"
                  >
                    Try For Free
                    <i class="fas fa-arrow-right ms-2"></i>
                  </b-button>
                  <p class="signUp-note">
                  No credit card required, try now!
                  </p>  
                </div>
    
              </div>
              
              <!-- Right Side Carousel -->
              <div class="col-lg-7 col-md-6">
                <div class="carousel-wrapper">
                  <carousel 
                    :autoplay="true" 
                    :autoplay-timeout="3000" 
                    :loop="true" 
                    :navigation-enabled="true"
                    :pagination-enabled="true"
                    :pauseOnHover="true"
                    :per-page="1"
                    class="modern-carousel"
                  >
                    <slide v-for="(pair, index) in imagePairs" :key="index">
                      <div class="image-comparison">
                        <div class="comparison-container">
                          <div class="image-item">
                            <img :src="pair.originalImage" alt="Original Image" class="comparison-image" />
                            <div class="image-label">Uploaded Image</div>
                          </div>
                          <div class="image-item">
                            <img :src="pair.transformedImage" alt="Transformed Image" class="comparison-image" />
                            <div class="image-label">Created with BRIKS </div>
                          </div>
                        </div>
                      </div>
                    </slide>
                  </carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- How It Works Section -->
    <section class="how-it-works">
    <div class="container">
      <div class="section-header text-center">
        <h2 class="section-title">Create Stunning Visuals in 3 Simple Steps!</h2>
        <div class="gradient-bar mx-auto"></div>
      </div>
      
      <div class="steps-container">
        <div class="step-grid">
          <!-- Step 1 -->
          <div class="step-card" :class="{ 'hover-effect': true }">
            <div class="step-number">1</div>
            <div class="step-image-container">
              <img src="@/assets/images/pages/uploadimage-briks.ai-website.png" alt="Upload or Connect Store" class="step-image" />
            </div>
            <h3 class="step-title">Upload Images</h3>
            <p class="step-description">
              Simply upload multiple images from various angles taken on your phone
            </p>
          </div>

          <!-- Step 2 -->
          <div class="step-card" :class="{ 'hover-effect': true }">
            <div class="step-number">2</div>
            <div class="step-image-container">
              <img src="@/assets/images/briksai.png" alt="AI Processing" class="step-image" />
            </div>
            <h3 class="step-title">AI Learns & Processes Your Images</h3>
            <p class="step-description">
              Our AI engine processes your images to create beautiful visuals and engaging social media content
            </p>
          </div>

          <!-- Step 3 with Carousel -->
          <div class="step-card" :class="{ 'hover-effect': true }">
            <div class="step-number">3</div>
            <div class="step-image-container">
              <carousel 
                :per-page="1"
                :autoplay="true"
                :autoplay-timeout="3000"
                :loop="true"
                :pagination-enabled="true"
                :pagination-padding="8"
                :pagination-size="8"
              >
                <slide v-for="(image, index) in resultImages" :key="index">
                  <img :src="image" :alt="'Result Image ' + (index + 1)" class="step-image" />
                </slide>
              </carousel>
            </div>
            <h3 class="step-title">Create Images in Seconds</h3>
            <p class="step-description">
              Just describe what you want as a simple text prompt, and our creative agents deliver both visuals and social media content in seconds
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

    <!-- Benefits Section -->
    <section class="benefits-section">
  <div class="container">
    <div class="benefits-grid">
      <!-- AI Images Benefit -->
      <div class="benefit-card">
        <div class="benefit-icon">
          <feather-icon icon="CheckCircleIcon" size="48" class="benefit-icon"/>
        </div>
        <h3>Images That Feel Real, Not AI-Generated</h3>
        <p>Experience photorealistic images across diverse styles and categories, delivering studio-quality results</p>
      </div>

      <!-- Speed Benefit -->
      <div class="benefit-card">
        <div class="benefit-icon">
          <feather-icon icon="ZapIcon" size="48" class="benefit-icon"/>
        </div>
        <h3>100X Faster Than Traditional Photoshoots</h3>
        <p>Cut down on costly and time-consuming photoshoots—focus your efforts on driving sales and scaling your marketing impact</p>
      </div>

      <!-- Cost Benefit -->
      <div class="benefit-card">
        <div class="benefit-icon">
          <feather-icon icon="TrendingUpIcon" size="48" class="benefit-icon"/>
        </div>
        <h3>Save Thousands on Content Creation</h3>
        <p>Powered by cutting-edge AI technology, our platform delivers unmatched, professional-grade results every time</p>
      </div>
    </div>
  </div>

</section>

    <!-- Product Features Grid Section -->
  <section class="features-grid ">
    <div class="container">
      <div class="features-container">
        <!-- Specialist Card -->

        <!-- Product Types Card -->
        <div class="feature-card dark">
          <h3>Studio-quality Photos in Seconds</h3>
          <div class="gradient-bar-features"></div>
          <p>Designed for fashion, automotive, and furniture brands with precision-crafted visuals. New categories and product video creation tools are coming soon.</p>
          <div class="social-features">
    <div class="social-feature">
      <i class="fas fa-magic"></i>
      <span>Create product images with simple text prompts</span>
    </div>
    <div class="social-feature">
      <i class="fas fa-image"></i>
      <span>Professional photoshoot-quality images with complete scene transformations</span>
    </div>
    <div class="social-feature">
      <i class="fas fa-video"></i>
      <span>Product video creation (coming soon)</span>
    </div>
  </div>
          <b-button 
          :to="'/explore'" 
      class="featured-button upload-btn"
    >
      See Samples 
      <i class="fas fa-arrow-right ms-2 "></i>
    </b-button>
        </div>
        
        

        <!-- Laptop Image -->
        <div class="feature-card image-card">
          <img src="https://media.briks.ai/images/image-68574263-9c04-4565-b32d-77b07f9e0d05.png" alt="Specialist working on laptop" class="feature-image laptop-image" />
        </div>

        <!-- Chair Image -->
        <div class="feature-card image-card">
          <img src="https://briks.chat/images/digital-transformation-02.png" alt="Furniture in cafe setting" class="feature-image" />
        </div>
        <div class="feature-card dark">
          <h3>Human-Guided AI Creation</h3>
          <div class="gradient-bar-features"></div>
          <p>Every paid plan includes expert-curated images refined by our specialists for flawless results that align with your brand. Our service helps to completely automate your content creation process.</p>
          <div class="social-features">
    <div class="social-feature">
      <i class="fas fa-magic"></i>
      <span>AI + human creativity </span>
    </div>
    <div class="social-feature">
      <i class="fas fa-check"></i>
      <span>Images created and curated by experts</span>
    </div>
    <div class="social-feature">
      <i class="fas fa-calendar"></i>
      <span>Weekly curated image sets</span>
    </div>
  </div>
    <b-button 
      @click="navigateToCreateAgent" 
      class="featured-button upload-btn"
    >
      Start Exploring Now
      <i class="fas fa-arrow-right ms-2"></i>
    </b-button>

        </div>
        
              <!-- Product Types Card -->
              <div class="feature-card dark">
  <h3>AI Social Media Assistant</h3>
  <div class="gradient-bar-features"></div>
  <p>Get platform-optimized captions, trending hashtags, and engaging copy for every social network. Our AI assistant helps you create thumb-stopping content for Instagram, Twitter, Facebook and more — all tailored to your brand voice.</p>
  
  <div class="social-features">
    <div class="social-feature">
      <i class="fab fa-instagram"></i>
      <span>Chat with our AI creative assistant to create social media posts and iterate together</span>
    </div>
    <div class="social-feature">
      <i class="fas fa-hashtag"></i>
      <span>Trending hashtags</span>
    </div>
    <div class="social-feature">
      <i class="fas fa-coffee"></i>
      <span>One-click social media posting (coming soon)</span>
    </div>
  </div>

  <b-button 
  @click="navigateToCreateAgent" 
    class="featured-button upload-btn"
  >
    Start Creating Now
    <i class="fas fa-arrow-right ms-2"></i>
  </b-button>
</div>

        <div class="feature-card image-card">
          <img src="https://media.briks.ai/images/image-153f5b78-61a8-4ddf-8e2f-62005d649f95.jpg" alt="Furniture in cafe setting" class="feature-image" />
        </div>
  
 

      </div>

    </div>
    
  </section>


  <!-- <section class="stats-section">
  <div class="container">
    <div class="stats-grid">
      Engagement Stat
      <div class="stat-card">
        <div class="stat-image-container">
          <img src="@/assets/images/Modern-Business-Suite.svg" class="stat-image" alt="engagement illustration" />
        </div>
        <h3 class="stat-title">More Engagement</h3>
        <p class="stat-description">
          Drive 3x more engagement with lifestyle visuals created instantly!
        </p>
        <div class="stat-gradient-bar"></div>
      </div>

      Business Success Stat
      <div class="stat-card">
        <div class="stat-image-container">
          <img src="@/assets/images/bs-01.svg" class="stat-image" alt="business illustration" />
        </div>
        <h3 class="stat-title">Happy Businesses</h3>
        <p class="stat-description">
          Join 1,000+ businesses saving hours of time and thousands of dollars on product photography
        </p>
        <div class="stat-gradient-bar"></div>
      </div>

      Time Saving Stat
      <div class="stat-card">
        <div class="stat-image-container">
          <img src="@/assets/images/automate.svg" class="stat-image" alt="time saving illustration" />
        </div>
        <h3 class="stat-title">Save Time</h3>
        <p class="stat-description">
          Create content 10x faster than traditional photoshoots
        </p>
        <div class="stat-gradient-bar"></div>
      </div>
    </div>
  </div>

</section> -->


 

  
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import { BButton } from 'bootstrap-vue';

export default {
  name: 'HomePage',

  metaInfo() {
    const pageTitle = '🚀 Transform Your Product Photos into Realistic Lifestyle Images with AI';
    const pageDescription = 'Transform phone photos into stunning marketing visuals in seconds with BRIKS.AI. Create professional product images for fashion, furniture & automotive without expensive equipment or studio.';
    const pageUrl = 'https://briks.ai/';
    const primaryImage = {
        url: 'https://media.briks.ai/images/image-2984bfd7-2f01-4efe-a160-155595f25458.png',
        secureUrl: 'https://media.briks.ai/images/image-2984bfd7-2f01-4efe-a160-155595f25458.png',
        width: '1200',
        height: '630',
        type: 'image/png',
        alt: 'BRIKS.AI Product Photography Example'
    };
    const additionalImages = [
      'https://media.briks.ai/images/image-fbf49b08-799a-4428-a5a7-af36686fa491.jpg',
      'https://media.briks.ai/images/image-2984bfd7-2f01-4efe-a160-155595f25458.png',
      'https://media.briks.ai/images/image-94078b12-7518-4534-8349-ec88d872de57.png'
    ];

    return {
      title: pageTitle,
      titleTemplate: '%s | BRIKS.AI',
      meta: [
        // Primary SEO
        { name: 'description', content: pageDescription },
        { name: 'keywords', content: 'AI product photography, fashion photography, product images, marketing visuals, AI image generation, product shots, ecommerce photography' },
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'BRIKS.AI' },
        
        // OpenGraph
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'BRIKS.AI' },
        { property: 'og:title', content: pageTitle },
        { property: 'og:description', content: pageDescription },
        { property: 'og:image', content: primaryImage.url },
        { property: 'og:image:secure_url', content: primaryImage.secureUrl },
        { property: 'og:image:type', content: primaryImage.type },
        { property: 'og:image:width', content: primaryImage.width },
        { property: 'og:image:height', content: primaryImage.height },
        { property: 'og:image:alt', content: primaryImage.alt },
        { property: 'og:url', content: pageUrl },
        { property: 'og:locale', content: 'en_US' },

        // Twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@BRIKSAI' },
        { name: 'twitter:title', content: pageTitle },
        { name: 'twitter:description', content: pageDescription },
        { name: 'twitter:image', content: primaryImage.url },
        { name: 'twitter:image:alt', content: primaryImage.alt },
        
        // Mobile
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=5.0' },
        { name: 'theme-color', content: '#DC123E' }
      ],
      link: [
        { rel: 'canonical', href: pageUrl },
        { rel: 'home', href: pageUrl }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "BRIKS.AI",
            "url": pageUrl,
            "description": pageDescription,
            "applicationCategory": "Photography",
            "image": [primaryImage, ...additionalImages],
            "screenshot": primaryImage,
            "offers": {
              "@type": "Offer",
              "category": "Professional Photography Tools",
              "price": "0",
              "priceCurrency": "USD",
              "description": "Start free, no credit card required"
            },
            "featureList": [
              "AI-powered image transformation",
              "Professional photo backgrounds",
              "Custom scene creation",
              "Multiple style options",
              "Instant processing"
            ],
            "creator": {
              "@type": "Organization",
              "name": "BRIKS.AI",
              "url": pageUrl,
              "logo": {
                "@type": "ImageObject",
                "url": "https://media.briks.ai/briks_logo.png"
              }
            }
          }
        }
      ]
    };
},

  components: {
    Carousel,
    Slide,
    BButton
  },
  
  data() {
    return {
      imagePairs: [
      {
          originalImage: 'https://media.briks.ai/images/whiteweddingdress.png',
          transformedImage: 'https://media.briks.ai/images/image-73304b6b-357c-44e5-b5b8-b5349f9571e2.png',
        }, 
        {
          originalImage: 'https://media.briks.ai/images/blue_dress.jpg',
          transformedImage: 'https://media.briks.ai/images/blue_dress_changed.png',
        },
        {
          originalImage: 'https://media.briks.ai/images/brown_dress.png',
          transformedImage: 'https://media.briks.ai/images/image-25bdf985-e77a-4f40-8155-2f1ac0d172c2.png',
        },
        {
          originalImage: 'https://media.briks.ai/images/bag.png',
          transformedImage: 'https://media.briks.ai/images/image-3fd14bbe-7b60-4c25-8ece-2cf4dda3e89b.png',
        }, 
      ],
      resultImages: [
      'https://media.briks.ai/images/image-35dace54-ddd5-4c72-b9fe-4f2598b762c7.png',
      'https://media.briks.ai/images/image-73304b6b-357c-44e5-b5b8-b5349f9571e2.png',
      'https://media.briks.ai/images/image-767dff8f-5526-4526-897c-ddd96483f611.png'
    ]
    };
  },


  methods: {
    navigateToCreateAgent() {
      try {
        this.$router.push({ name: 'agents' });
      } catch (error) {
        console.error('Navigation error:', error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>


html, body {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

.landing-container {
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  // position: relative;
  background-color: #000000;
}

.masthead {
  position: relative;
  padding: 1rem 0;
  padding-bottom: 0rem; 
  background: url('~@/assets/images/banner/bg.png'); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.masthead-title {
  color: white; 
  font-size: 2.4rem; 
  padding-left: 30px; 
  margin-bottom: 20px; 
  line-height: 1.2;
  white-space: normal;
  overflow: visible;
}

.masthead-gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(130deg, rgba(189, 41, 41, 0.98) 0%, rgba(0,0,0,0.95) 0%);
}

.briks-gradient-bar {
  height: 6px;
  width: 100%;
  max-width: 300px;
  background: linear-gradient(90deg, #DC123E 50%, #FFFFFF 80%);
  border-radius: 3px;
}

.content-wrapper {
  position: relative;
  margin: 0;  // Remove margins
  padding: 3rem;  // Add padding instead
  z-index: 1;
  width: 100%;
  max-width: 100vw;
}

.headline {
  font-size: 2.8rem;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 1.2;
  margin: 1rem 0;
  padding-top: 0px;
}

.description {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  margin-top: -15px;
}
.signUp-note {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  font-style: italic;
  line-height: 1;
  margin-bottom: 2rem;
  margin-top: 15px;
  margin-left: 5px;
}

.features-list {
  margin: 0rem 0;
  padding-bottom: 10px;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  
  .feature-dot {
    width: 6px;
    height: 6px;
    background: linear-gradient(90deg, #4b9fff 80%, #9B6EF3 30%);
    border-radius: 50%;
    margin-right: 1rem;
  }
}

.start-button {
  background: linear-gradient(90deg, #DC123E 100%, #FFFFFF 0%);
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 2rem;
  margin-left: 0rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(74, 144, 226, 0.3);
  }
}

.upload-btn {
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 8px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.upload-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

/* Add pulse animation for extra attention */
@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(0, 255, 213, 0.5); }  /* Bright teal */
  70% { box-shadow: 0 0 0 10px rgba(0, 255, 213, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 255, 213, 0); }
}

.upload-btn:not(:hover) {
  animation: pulse 2s infinite;
}
.featured-button {
  background: linear-gradient(90deg, #DC123E 100%, #FFFFFF 0%);

  width: 50%;
  border: none;
  // padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 2rem;
  margin-top: .5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(74, 144, 226, 0.3);
  }
}

.social-features {
  margin: 1.5rem 0;
  display: grid;
  gap: 1rem;
}

.social-feature {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: rgba(255, 255, 255, 0.9);
  
  i {
    color: #DC123E;
    font-size: 1.2rem;
  }
}

.carousel-wrapper {
  padding: 1rem;
  border-radius: 1rem;
}

.image-comparison {
  .comparison-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    background: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border-radius: 0.5rem;
  }
}

.image-item {
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  
  .comparison-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 0.5rem;
  }
  
  .image-label {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 0.5rem;
    text-align: center;
    font-size: 0.9rem;
  }

  
}

// Responsive styles
@media (max-width: 768px) {
  .headline {
    font-size: 2rem;
  }
  
  .content-section {
    padding: 0 1rem;
    margin-bottom: 2rem;
    
  }
  
  .carousel-wrapper {
    padding: 0.5rem;
  }
  
  .comparison-container {
    grid-template-columns: 1fr;
  }
}
</style>

<style lang="scss" scoped>

/* Add these new styles for How It Works section */
.how-it-works {
  background-color: #000000;
  padding: 0rem 0 0rem;
  color: white;
  margin-top: 1rem;
}

.section-header {
  margin-bottom: 1rem;
  margin-top: -1rem;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0rem;
 
  color: #FFFFFF;
}

.gradient-bar {
  height: 6px;
  width: 100px;
  background: linear-gradient(90deg, #DC123E 60%, #FFFFFF 80%);
  border-radius: 3px;
  margin-top: 1rem;
  margin-bottom: 6rem;
}
.gradient-bar-features {
  height: 6px;
  width: 170px;
  background: linear-gradient(90deg, #DC123E 60%, #FFFFFF 80%);
  border-radius: 3px;
  margin-top: 0rem;
  margin-bottom: 2rem;
}

.step-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 0 auto;
  max-width: 1600px;
  padding: 0 1rem;
}

.step-card {
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  padding: 5.5rem 1rem 0rem;  /* Reduced padding */
  text-align: center;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0.5rem;
}

.step-number {
  margin-top: 1rem; 
  position: absolute;
  top: -15px; /* Moved up further from image */
  left: 50%;
  transform: translateX(-50%);
  width: 40px; /* Increased size slightly */
  height: 40px;
  background: linear-gradient(90deg, #DC123E 100%, #FFFFFF 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
  z-index: 2; /* Ensure number stays above other elements */
}

.step-image-container {
  margin-bottom: 2rem; /* Reduced margin */
  border-radius: 0.5rem;
  overflow: hidden;
  height: 320px; /* Increased height */
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Changed to contain to match the illustration style */
    border-radius: 0.5rem;
    background-color: white; /* Added white background for illustrations */
  }
}

.step-image {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  transition: transform 0.3s ease;
  object-fit: cover;
  background-color: transparent;

  &:hover {
    transform: scale(1.05);
  }
}

.step-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0.5rem 0;
  min-height: auto; /* Removed fixed height */
  color: #FFFFFF;
}

.step-description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0.5rem;
  flex-grow: 1; /* Allows description to fill available space */
}

@media (max-width: 992px) {
  .step-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .step-grid {
    grid-template-columns: 1fr;
  }

  .content-wrapper {
  position: relative;
  margin: 0;  // Remove margins
  padding: 1rem;  // Add padding instead
  z-index: 1;
  width: 100%;
  max-width: 100vw;
}

  .step-card {
    max-width: 400px;
    margin: 0 auto;
  }

  .section-title {
    font-size: 2rem;
  }
}

.stats-section {
  background-color: #000000;
  margin-bottom: 50px;
  margin-top: 30px;
  position: relative;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  
  margin: 0 auto;
  padding: 0 2.5rem;
}

.stat-card {
  background: rgba(99, 99, 99, 0.486);
  border-radius: 1rem;
  padding: 2.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-image-container {
  width: 100%;
  max-width: 200px;
  margin-bottom: 1.5rem;
}

.stat-image {
  width: 220px;
  height: 220px;
  object-fit: contain;
}

.stat-title {
  color: #FFFFFF;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.stat-description {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  max-width: 80%;
}

.stat-gradient-bar {
  height: 4px;
  width: 40px;
  background: linear-gradient(90deg, #DC123E 0%, rgba(255, 255, 255, 0.8) 100%);
  border-radius: 2px;
  margin: 0 auto;
}

@media (max-width: 992px) {
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 0 1rem;
  }

  .stat-card {
    padding: 2rem;
  }

  .stat-image-container {
    max-width: 150px;
  }

  .stat-title {
    font-size: 1.3rem;
  }

  .stat-description {
    font-size: 1rem;
    max-width: 90%;
  }
}

.features-grid {
  background-color: #000000;
  padding: 2 rem;
  @media (max-width: 768px) {
    padding: 1rem;
  }
  
}

.features-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  // max-width: 1400px;
  // max-height: 1400px;
  padding-top: 0px;
  padding-bottom: 50px;
  margin: 2.5rem;
  margin-bottom: 0rem;
  background-color: #000000;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin: 1rem;
    
    // Hide image cards on mobile
    .feature-card.image-card {
      display: none;
    }
  }
}

.feature-card {
  border-radius: 1rem;
  overflow: hidden;
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  position: relative; 
  &.dark {
    background: rgba(99, 99, 99, 0.486);
    @media (max-width: 768px) {
      height: auto;
      min-height: 300px;
      padding: 2rem;
    }
  }
  .image-wrapper {
      width: 80%;
      height: 100%;
      position: relative;
    }

    &.image-card {
    padding: 0;
    overflow: hidden;
    position: relative;
    height: 400px;
    width: 100%;
    img.feature-image {
      object-fit: cover;
      border-radius: 1rem;
    }
    
    img.feature-image {
      object-fit: cover;
      border-radius: 1rem;
    }
  }

  h3 {
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    @media (max-width: 768px) {
      font-size: 1.3rem;
    }
  }

  p {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.1rem;
    line-height: 1.6;
    @media (max-width: 768px) {
      font-size: 1rem;
      line-height: 1.4;
    }
  }
}

  .feature-image {
      width: 100%;
      height: 100%;
      object-fit: cover; // This maintains aspect ratio while filling the container
      border-radius: 1rem; // Match the card's border radius
      position: absolute;
      top: 0;
      left: 0;
    }

.benefits-section {
  background-color: #000000;
  padding: 0rem 0;
  margin-top: 3rem;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1600px;
  max-height: auto;
  margin: 0 auto;
  padding: 0 1rem;
  margin-top: 4rem;
  margin-bottom: 6rem;
}

.benefit-card {
  text-align: center;
  padding: 2rem;
}

.benefit-icon {
  color: #DC123E;
  margin-bottom: 1.5rem;
  
  svg {
    width: 70px;
    height: auto;
  }
}

.benefit-card h3 {
  color: #FFFFFF;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.benefit-card p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .features-container {
    grid-template-columns: 1fr;
  }

  .benefits-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .feature-card {
    height: auto !important;
    min-height: 250px;
  }

  .featured-button {
  background: linear-gradient(90deg, #DC123E 100%, #FFFFFF 0%);

  width: 100%;
  border: none;
  // padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 2rem;
  margin-top: .5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(74, 144, 226, 0.3);
  }
}
}
</style>
<style lang="scss" scoped>
/* How It Works Section Mobile Optimizations */
.how-it-works {
  padding: 3rem 0;
  
  @media (max-width: 768px) {
    padding: 2rem 0;
    
    .container {
      padding: 0 1rem;
    }
    
    .section-header {
      margin-bottom: 2rem;
    }
    
    .section-title {
      font-size: 2rem;
      padding: 0 1rem;
    }
    
    .gradient-bar {
      margin-bottom: 2rem;
    }
  }
}

.step-grid {
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 0;
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 3rem;
    max-width: 100%;
    padding: 0 1rem;
  }
}

.step-card {
  @media (max-width: 768px) {
    margin: 0;
    padding: 4rem 1rem 1.5rem;
    max-width: 100%;
    
    &:hover {
      transform: none;
    }
  }
}

.step-image-container {
  @media (max-width: 768px) {
    height: 250px;
    margin-bottom: 1.5rem;
    
    .VueCarousel {
      height: 250px;
    }
  }
}

/* Features Grid Mobile Optimizations */
.features-grid {
  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.features-container {
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin: 1.5rem;
  }
  
  @media (max-width: 768px) {
    margin: 1rem;
    gap: 1rem;
  }
}

.feature-card {
  @media (max-width: 768px) {
    height: auto !important;
    min-height: 300px;
    padding: 2rem;
    
    &.image-card {
      height: 300px;
      
      .feature-image {
        height: 100%;
      }
    }
    
    &.dark {
      padding: 1.5rem;
    }
    
    h3 {
      font-size: 1.3rem;
      margin-bottom: 0.75rem;
    }
    
    p {
      font-size: 1rem;
      line-height: 1.4;
    }
  }
}

.social-features {
  @media (max-width: 768px) {
    margin: 1rem 0;
    gap: 0.75rem;
    
    .social-feature {
      font-size: 0.9rem;
    }
  }
}

/* Carousel Responsive Fixes */
.carousel-wrapper {
  @media (max-width: 768px) {
    .image-comparison {
      .comparison-container {
        grid-template-columns: 1fr;
        gap: 0.5rem;
      }
      
      .image-item {
      
        
        .image-label {
          font-size: 0.8rem;
          padding: 0.25rem;
        }
      }
    }
  }
}

/* Button Responsive Fixes */
.featured-button {
  @media (max-width: 768px) {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    margin-top: 1rem;
  }
}

/* Benefits Section Mobile Optimizations */
.benefits-section {
  @media (max-width: 768px) {
    padding: 0rem 0;
    margin-top: 0rem;
  }
}

.benefits-grid {
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 0 1.5rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
}

.benefit-card {
  @media (max-width: 768px) {
    padding: 1.5rem;
    
    h3 {
      font-size: 1.2rem;
    }
    
    p {
      font-size: 0.95rem;
    }
  }
}
</style>
